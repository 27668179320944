<template>
  <div
    class="popup_wrap"
    style="width:900px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.NEWB010P120.003') }}</h1><!-- RF 위험물 사전승인번호 선택 -->
      <div class="content_box">
        <table class="tbl_col mt10">
          <colgroup>
            <col style="width:auto">
            <col style="width:11%">
            <col style="width:11%">
            <col style="width:8%">
            <col style="width:10%">
            <col style="width:15%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.NEWB010P120.004') }}</th><!-- Reference No. -->
              <th>{{ $t('msg.NEWB010P120.005') }}</th><!-- 출발 -->
              <th>{{ $t('msg.NEWB010P120.006') }}</th><!-- 도착 -->
              <th>{{ $t('msg.NEWB010P120.007') }}</th><!-- Class -->
              <th>{{ $t('msg.NEWB010P120.008') }}</th><!-- UN No. -->
              <th>{{ $t('msg.NEWB010P120.009') }}</th><!-- Commodity -->
              <th>{{ $t('msg.NEWB010P120.010') }}</th><!-- 제조사 -->
              <th>{{ $t('msg.NEWB010P120.011') }}</th><!-- 승인여부 -->
              <th>{{ $t('msg.NEWB010P120.012') }}</th><!-- 선택 -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in items" :key="index">
              <td>{{ item.apvNo }}</td>
              <td>{{ item.polPortCd }}</td>
              <td>{{ item.podPortCd }}</td>
              <td>{{ item.imoClasCd }}</td>
              <td>{{ item.imdunNo }}</td>
              <td>{{ item.rmk }}</td> <!--Commodity(보류)-->
              <td>{{ item.actShprCstEnm }}</td> <!-- 제조사(보류)-->
              <td>{{ item.apvStsNm }}</td> <!-- 상태코드(보류) -->
              <td><a class="button blue sm" @click.prevent="onSelect(item)">{{ $t('msg.NEWB010P120.013') }}</a></td><!-- 선택 -->
              <!-- <td><a class="button gray sm disabled" href="#">불가</a></td> -->
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>
<script>

import rfDg from '@/api/rest/trans/rfDg'

export default {
  name: 'RfDgRequestPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          selectedRow: 0,
          polPortCd: '',
          podPortCd: ''
        }
      }
    }
  },
  data () {
    return {
      items: []
    }
  },
  created: function () {
    const param = {
      polPortCd: this.parentInfo.polPortCd,
      podPortCd: this.parentInfo.podPortCd
    }

    rfDg.getRfDgRequestList(param).then((data) => {
      const alertOpt = {
        alertType: 'normal',
        customCloseBtnText: this.$t('msg.NEWB010P120.001'), // 닫기
        type: 'info'
      }

      if (data.headers.respcode === 'T0012') {
        this.$ekmtcCommon.alert({ ...alertOpt, message: this.$t('msg.NEWB010P120.002') }) // 로그인을 확인해주세요.
        this.$emit('close')
      } else {
        const rfDgRequestList = data.data
        this.items = rfDgRequestList.map((item) => {
          // let stsCd = item.apvStsCd

          // if (stsCd === '01') {
          //   item.apvStsNm = 'Request'
          // } else if (stsCd === '03') {
          //   item.apvStsNm = 'Reject'
          // } else if (stsCd === '04') {
          //   item.apvStsNm = 'Checking'
          // }
          return item
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  },
  methods: {
    //데이터 선택
    onSelect (item) {
      const obj = {
        apvNo: item.apvNo,
        selectedRow: this.parentInfo.selectedRow
      }

      this.$emit('selectFunc', obj)
      this.$emit('close')
    }
  }
}
</script>
