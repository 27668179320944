var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c("button", {
        staticClass: "layer_close",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.NEWB010P120.003"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.004")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.005")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.006")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.007")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.008")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.009")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.010")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.011")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.012")))]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.items, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(item.apvNo))]),
                  _c("td", [_vm._v(_vm._s(item.polPortCd))]),
                  _c("td", [_vm._v(_vm._s(item.podPortCd))]),
                  _c("td", [_vm._v(_vm._s(item.imoClasCd))]),
                  _c("td", [_vm._v(_vm._s(item.imdunNo))]),
                  _c("td", [_vm._v(_vm._s(item.rmk))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.actShprCstEnm))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.apvStsNm))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "button blue sm",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSelect(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.013")))]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "11%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }